import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './Biography.css';
import Photo from '../img/biographyimg.JPG';

const Biography = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <section id="biography" className="biography">
      <div className="biography-content">
        {/* Анимация текста: появляется снизу */}
        <motion.div
        className={`biography-text ${isExpanded ? 'expanded' : ''}`}
        initial={{ opacity: 0, y: 80 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: 'easeOut' }}
        >
  <h1>Биография</h1>
  <div className="text-container">
    <p>
      
    Хочу поделиться с вами историей своего пути. 
    Я с детства была в психологии и читала Карнеги, так как моя мама работала педагогом-психологом.
    В 18 лет, как только я окончила школу у меня был выбор: с чем связать свою жизнь? 
    Я занимала танцами, окончила художественную школу, посещала кружок актерского мастерства, пела в школьном хоре..
    но это все скорее было увлечением, ведь психология уже была частью моей жизни. 
    Я поступила в университет при МЧС, так как хотела стать психологом именно в этой сфере.  
      <br />
      Чтобы заработать себе на обучение я, совершенно случайно, оказалась в общепите и надолго осталась там. Я стремительно выросла в должности и приняла решение не менять работу, ведь я достигла больших высот и менять деятельность было уже нецелесообразно, думала я. 
      Спустя 7 лет работы я все чаще начала испытывать выгорание, стресс и понимала, что этот род деятельности совсем не мой. Мне было страшно сделать какие-либо самостоятельные шаги в сторону изменений, поэтому я решила - иду в личную терапию, за помощью!
      В 2018 году я впервые посетила психолога и началась моя «другая» жизнь. Я не сразу ушла с общепита, сложно признаться, но я много раз опускала руки и шла обратно, уже в знакомую сферу.
      Было очень страшно уйти от туда, куда стремятся многие и начинать все с нуля.

      В 2019 году я зарегестрировалась на платформе для психологов и, с поддержкой своего терапевта взяла первого клиента!
      Помню,свое состояние после сессии, волнение и тревога были невероятными, но у меня получилось и я почувствовала - это мое дело!
      Параллельно в моей жизни происходило много сложностей: я пережила сложный разрыв отношений, переехала в другой город, выстраивала жизнь заново и это все происходило в один год. 
      В 2020 году я начала обучение в направлении Гештальт и до сих пор считаю это направление самым близким мне по духу. 
      В 2021 году я узнала об интересном направлении - сексология
      Выбрала университет и прошла программу обучения. Теперь сексология часть моей работы.

      Благодаря терапии и постоянной работе над собой я смогла построить не только карьеру, но и крепкие отношения, которые переросли в семью. На данный момент я замужем и у меня есть дочь. Я очень счастливый человек, что и транслирую в своей работе.
    </p>
  </div>
  
  {/* Кнопка для отображения полного текста с галочкой */}
  <button className="toggle-button" onClick={toggleText}>
    {isExpanded ? 'Скрыть' : 'Читать полностью'}
  </button>
</motion.div>


        {/* Анимация изображения: просто opacity */}
        <motion.div
          className="biography-img"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 1, ease: 'easeOut' }}
        >
          <img src={Photo} alt="biographyImg" className="biography-image" />
        </motion.div>
      </div>
    </section>
  );
};

export default Biography;
